import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent, builder } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import '@builder.io/widgets';
import '../widgets';
import { config } from '../config';
import {
  PageThemeProvider,
  GoogleTagManagerService as gtmService,
  BackgroundSizeType,
  BackgroundTilingType,
  BackgroundPositionType,
  BackgroundAttachmentType,
  LocalFilesContext,
  LocalFilesType,
  WidgetNames,
  StreamUnlockVariant
} from '@umg/mira-widgets';
import { ErrorTypes } from '../enums';
import logger from '../errorHandling';

const builderApiKey = config.BUILDER_API_KEY;
if (builderApiKey && builderApiKey !== '') {
  builder.init(builderApiKey);
}

interface PageTemplateProps {
  data?: { allBuilderModels: { page: { content: any, localFiles: LocalFilesType[] }[] } }
  children?: React.ReactElement<any, any>
  pageContext: {
    pageId: string
  }
}

interface MetaInfo {
  property: string;
  content: string;
}

const getGeneratedImageUrl = (campaignData) => {
  if (typeof window !== 'undefined' && window?.location && config.ASSETS_URL) {
    const urlParams = new URLSearchParams(window.location.search);
    const imageID = urlParams.get('image');
    if (campaignData && imageID) {
      return `${config.ASSETS_URL}${campaignData.id}/${imageID}`;
    }
  }
  return null;
};

// NOTE: if something is changed, it should be updated in mira-preview page template as well
const validDomains = [
  'https://*.umusic.com',
  'https://*.spotify.com',
  'https://sdk.scdn.co',
  'https://api.music.apple.com',
  'https://js-cdn.music.apple.com',
  'https://*.itunes.apple.com',
  'https://*.google-analytics.com',
  'https://*.dynatrace.com',
  'https://*.evidon.com',
  'https://www.googletagmanager.com',
  'https://fonts.googleapis.com',
  'https://*.lytics.io/',
  'https://connect.facebook.net',
  'https://facebook.com',
  'https://www.facebook.com',
  'https://juicer.io',
  'https://www.juicer.io',
  'https://playback.umgapps.com',
  'https://dev.playback.umgapps.com',
  'https://forms.umusic-online.com',
  'https://widget.smsinfo.io',
  'https://zaphod.vvhp.net',
  'https://games.saz-zad.com',
  'https://redtaylorsversion.taylorswift.com',
  'https://laylo.com',
  'https://umusic.app.box.com',
  'https://rocnation.us12.list-manage.com',
  'https://umusic.co.uk',
  'https://pgwh2.com',
  'https://talkshop.live',
  'https://glitch.ge',
  'https://*.glitch.ge',
  'https://lnk.to',
  'https://*.lnk.to',
  'https://www.stream.co.jp',
  'https://*.stream.co.jp',
  'https://soundcloud.com',
  'https://*.youtube.com',
  'https://www.google.com/maps/d/embed?*'
];

const containsSpotifyStreamToUnlock = (page: any) => {
  let result = false;

  page?.data?.blocks?.forEach((block: any) => {
    if (block?.component?.name === WidgetNames.STREAM_UNLOCK_SPOTIFY && block?.meta?.variant === StreamUnlockVariant.STREAM_UNLOCK) {
      result = true;
    } else if (Array.isArray(block?.children) && block?.children.length > 0) {
      for (let child of block.children) {
        if (child?.component?.name === WidgetNames.STREAM_UNLOCK_SPOTIFY && child?.meta?.variant === StreamUnlockVariant.STREAM_UNLOCK) {
          result = true;
        }
      }
    }
  });

  return result;
}

const PageTemplate: React.FC<PageTemplateProps> = (props) => {
  const { data, children, pageContext } = props;
  if (!data?.allBuilderModels?.page?.length) {
    return children;
  }
  const pageContent = data?.allBuilderModels.page[0]?.content;
  const localizedFiles = data?.allBuilderModels.page[0]?.localFiles;
  const campaign = pageContent?.data.campaign.value;
  const campaignID = pageContent?.data.campaign.id;
  const generatedImage = getGeneratedImageUrl(campaign);
  const { data: {
    backgroundImageAsset,
    backgroundVideoAsset,
    description,
    favIconAsset,
    metaImageAsset,
    metaTitle,
    router: {
      domain
    }
  } } = campaign;

  let customTheme = {};
  let css = '';
  let backgroundConfig = {
    backgroundSize: BackgroundSizeType.ACTUAL_SIZE,
    backgroundTiling: BackgroundTilingType.NONE,
    backgroundPosition: BackgroundPositionType.CENTERED,
    backgroundAttachment: BackgroundAttachmentType.FIXED,
  };

  if (campaign) {
    try {
      customTheme = JSON.parse(campaign.data.themeObject);
      css = campaign.data.css || '';

      if (campaign.data.backgroundConfig) {
        backgroundConfig = campaign.data.backgroundConfig;
      }
    } catch (error) {
      logger('Error fetching customTheme settings', ErrorTypes.Error, error);
    }
  }

  try {
    gtmService.initSession({
      artistName: campaign?.data?.artist?.name,
      campaignCountryCode: campaign?.data?.territory?.countryCode,
      campaignID,
      campaignTerritory: campaign?.data?.territory?.label?.en,
      campaignWidgetList: campaign?.data?.widgets || [],
      canopusID: campaign?.data?.artist?.canopusId,
      partyId: campaign?.data?.artist?.partyId,
      pageId: pageContext.pageId,
      eventAlreadyProcessed: process.env.ASYNC_STATS_DATA_COLLECTOR_ENABLED === 'true' // prevents GTM plugin to fetch data collector endpoint when eventAlreadyProcessed is true
    });
  } catch (error) {
    logger('Could not set GTM.', ErrorTypes.Error, error);
  }

  React.useEffect(() => {
    // give 3 seconds to fire this event.
    // some components are waiting for async calls to render
    setTimeout(() => {
      gtmService.outboundClickEvents();
    }, 3000);
  }, []);

  const getSocialNetworksMeta = (): JSX.Element[] => {
    const socialNetworkKeys = ['og', 'twitter'];
    const metaInfoProperties = [
      {
        property: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        property: 'og:type',
        content: 'website'
      }
    ] as MetaInfo[];

    socialNetworkKeys.forEach((key: string): void => {
      metaTitle && metaInfoProperties.push({
        property: `${key}:title`,
        content: metaTitle
      });
      description && metaInfoProperties.push(
        {
          property: `${key}:description`,
          content: description
        }
      );
      (!!(metaImageAsset && metaImageAsset.assetUrl) || !!generatedImage)
      && metaInfoProperties.push(
        {
          property: `${key}:image`,
          content: `${domain ? `https://${domain}`: '' }${generatedImage || metaImageAsset.assetUrl}?${new Date().getTime()}`
        }
      );
    });

    return metaInfoProperties.map(({ property, content }: MetaInfo) => (
      <meta
        key={property}
        property={property}
        content={content}
      />
    ));
  };

  return (
    <PageThemeProvider
      backgroundConfig={backgroundConfig}
      backgroundImageUrl={backgroundImageAsset && backgroundImageAsset.assetUrl || ''}
      backgroundVideoUrl={backgroundVideoAsset && backgroundVideoAsset.assetUrl || ''}
      customTheme={customTheme}
    >
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={description} />
        {containsSpotifyStreamToUnlock(pageContent) && (
          <meta httpEquiv="Content-Security-Policy" content={`default-src 'self' blob: data: https: wss:; style-src 'unsafe-inline' ${validDomains.join(' ')}; script-src 'unsafe-inline' 'unsafe-eval' ${validDomains.join(' ')}; connect-src wss: ${validDomains.join(' ')};`} />
        )}
        <link rel="shortcut icon" href={favIconAsset && favIconAsset.assetUrl || ''} />
        <style type="text/css">
          { css }
        </style>
        {
          pageContent?.data && getSocialNetworksMeta()
        }
      </Helmet>
      {!pageContent ?
        children
        :
        <LocalFilesContext.Provider value={localizedFiles}>
          <BuilderComponent content={pageContent} />
        </LocalFilesContext.Provider>
      }

    </PageThemeProvider>
  );
};

export default PageTemplate;

// This graphQL is run when gatsby does the build. For previews, we just pass
// the result as a prop, observing the expected format below.
export const pageQuery = graphql`
  query(
    $pageId: String
    $includeUnpublished: Boolean
  ) {
    allBuilderModels {
      page(
        query: { id: $pageId }
        limit: 1
        options: {
          cachebust: true,
          includeRefs: true,
          includeUnpublished: $includeUnpublished,
        }
      ) {
        content
        # download all image assets from content and make them available on localFiles Array
        localFiles {
          publicURL # mandatory field if you pass replaceLinksToStatic: true
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
